import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Importing Views
import PaymentComponent from './PaymentWidget/PaymentComponent';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <PaymentComponent
            keys={{
              stripe:
                'pk_live_51Kud1iDbgA8nzDnxjGSEDagkc7372ByoK8bYZRsjpO4MyY8dnR2jhloCtDyEhctd31wQ7kWIoFtyZmq12FuAuwwW00h8VJyaKJ',
            }}
          />
        </Route>
        <Route>
          <PaymentComponent
            keys={{
              stripe:
                'pk_live_51Kud1iDbgA8nzDnxjGSEDagkc7372ByoK8bYZRsjpO4MyY8dnR2jhloCtDyEhctd31wQ7kWIoFtyZmq12FuAuwwW00h8VJyaKJ',
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}
