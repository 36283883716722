import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CreditCardForm from './CreditCardForm';
import './PaymentComponent.css';
import './elements.css';
import { loadStripe } from '@stripe/stripe-js';

//config of fonts for the stripe prebuilt elements
const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

export default function PaymentComponent(props) {
  return (
    <main id='wrapper' style={{ width: '100%', minHeight: '100vh' }}>
      <div className='w-full flex flex-col items-center justify-center my-10 p-4'>
        <div className='flex flex-col items-center justify-center mb-6'>
          <img
            src={`${window.location.origin}/images/thebrandauthors.webp`}
            alt='The Brand Authors Logo'
            style={{ height: '60px', objectFit: 'cover' }}
            className='mb-6'
          />
          <div className='flex items-center justify-center'>
            <img
              src={`${window.location.origin}/images/stripe.webp`}
              alt='Powered by Stripe'
              style={{ height: '40px', objectFit: 'cover' }}
              className='mb-2'
            />
          </div>
        </div>

        {/* Elements Wrapper and checkout form component */}
        <Elements
          stripe={loadStripe(props.keys.stripe)}
          options={ELEMENTS_OPTIONS}
        >
          <CreditCardForm />
        </Elements>
      </div>

      <div id='wave'></div>
    </main>
  );
}
